#homepage {
  text-align: center;
}

#homepage div.row {
  margin-top: 20px;
}

#homepage img.bifa {
  display: block;
  width: 100px;
  height: 100px;
  margin: 0 auto;
}

#homepage div.services {
  font-size: 1.1em;
  font-weight: 500;
  margin: 0 0 50px 0;
}

#homepage div.actionButtons a.btn {
  width: 100%;
  padding-top: 20px;
  padding-bottom: 20px;
}

@media screen and (min-width: 576px) {

  #homepage {
    padding: 0 100px;
    text-align: left;
  }

}