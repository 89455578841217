#services .card-columns {
  column-count: 1;
}

#services div.price {
  margin: 60px 0;
}

#services div.price h4 {
  margin: 0px;
}

#services div.price em {
  color: #555555;
  font-size: 0.8em;
}

#services div.price span.pounds {
  font-size: 3rem;
}

#services a.btn-primary {
  display: block;
  padding: 20px 0;
}

@media screen and (min-width: 576px) {

  #services .card-columns {
    column-count: 3;
  }

  #services div.details {
    text-align: left;
  }
  
}