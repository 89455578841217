footer {
  background: #fbd966;
  color: #222222;
  padding-top: 20px;
  padding-bottom: 20px;
}

footer div.social, footer div.links {
  text-align: center;
}

footer div.links {
  margin-top: 10px;
}

footer a {
  text-decoration: none;
  color: #999999;
}

footer a:hover {
  text-decoration: none;
  color: #777777;
}

footer i.fa-facebook-square:hover {
  color: #2f82ed;
}

footer i.fa-twitter-square:hover {
  color: #369ded;
}

footer i.fa-youtube-square:hover {
  color: #f50602;
}

footer div.company-number, footer div.copyright {
  text-align: center;
}

footer div.bifa {
  text-align: center;
  margin-bottom: 10px;
}

footer div.container {
  padding: 0;
}

@media screen and (min-width: 576px) {

  footer div.links {
    margin-top: 0;
  }

}

@media screen and (min-width: 992px) {

  footer div.company-number {
    text-align: left;
  }

  footer div.copyright {
    text-align: right;
  }

  footer div.bifa {
    text-align: right;
  }

}