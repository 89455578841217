header {
  background: #FFFFFF;
  height: 70px;
}

// header video {
//   display: none;
//   position: absolute;
//   top: 0;
//   left: 50%;
//   min-width: 100%;
//   min-height: 100%;
//   width: auto;
//   height: auto;
//   transform: translateX(-50%) translateY(-25%);
//   clip-path: inset(0 0 calc(100% - 563px) 0);
//   opacity: 0.6;
// }

header a.logo img {
  display: block;
  height: 70px;
  margin: auto;
}

header a.sign-in {
  display: none;
}

nav {
  background: #5b9bd5;
}

nav.navbar {
  padding: 0;
}

nav.navbar div.container {
  padding: 0;
}

nav ul.navbar-nav.my-2 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

nav li.nav-item {
  line-height: 2.5;
  padding-left: 12px;
  padding-right: 12px;
}

nav li.nav-item a {
  color: #212529;
}

nav li.nav-item.active, nav li.nav-item:hover {
  background: #697bfb;
}

nav button.navbar-toggler {
  padding: 10px;
}

@media screen and (min-width: 576px) {

  header {
    height: 300px;
    background-image: url('https://images.cwlclearances.co.uk/header.png');
    background-size: cover;
    color: #000000;
  }
  
  // header video {
  //   display: block;
  // }

  header a.logo img {
    height: 140px;
    margin-top: 30px;
}

  header div.title h1 {
    font-size: 1.7em;
    margin-top: 38px;
    text-align: center;
  }

}

@media screen and (min-width: 992px) {

  header div.title h1 {
    font-size: 2em;
    margin-top: 75px;
    text-align: right;
  }

  header a.sign-in {
    display: block;
    background: #FFFFFF;
    width: 200px;
    padding: 10px 0 12px 0;
    border-left: solid 1px #5b9bd5;
    border-bottom: solid 1px #5b9bd5;
    border-right: solid 1px #5b9bd5;
    text-align: center;
    position: fixed;
    top: 0px;
    right: 100px;
    z-index: 99999;
  }

}