#body {
  background: #FFFFFF;
  padding-top: 20px;
  padding-bottom: 20px;
}

#body img {
  width: 100%;
}

@media screen and (min-width: 576px) {

  #body {
    padding-top: 50px;
    padding-bottom: 50px;
  }

}