#applicationWizard {
  display: block;
  padding: 20px;
  margin: 0 auto;
}

#applicationWizard textarea {
  resize: none;
}

#applicationWizard table.review th {
  text-align: right;
  width: 210px;
}

#applicationWizard div.review {
  margin-bottom: 20px;
  padding: 10px;
}

#applicationWizard button.submit {
  margin-top: 10px;
}