div.save-bar {
  background: #EEEEEE;
  position: fixed;
  bottom: 0px;
  left: 0px;
  width: 100%;
  padding: 10px;
}

div.save-bar button {
  float: right;
}