#client nav {
  background: #5b9bd5;
}

#client nav form {
  margin-right: 10px;
}

#client nav a.navbar-brand img {
  width: 150px;
}