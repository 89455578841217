@import '../../../../node_modules/bootstrap/dist/css/bootstrap.css';

@import 'header';
@import 'footer';
@import 'body';
@import 'search';
@import 'loginForm';
@import 'forgotPassword';
@import 'resetPassword';
@import 'applicationWizard';
@import 'homepage';
@import 'services';
@import 'client/index';

body {
  font-family: 'Work Sans', sans-serif;
  background: #EEEEEE;
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Work Sans', sans-serif;
}

a {
  color: #5b9bd5;
}

a:hover {
  color: #697bfb;
  text-decoration: none;
}

div.card {
  margin-bottom: 10px;
}

.debug-red {
  border: dashed 1px #F00;
}

.form-control {
  border-radius: 0;
}

.btn {
  border-radius: 0;
}